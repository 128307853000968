<template>
  <div class="bar-chart">
    <VueECharts :option="getChartData" autoresize ref="chart" />
  </div>
</template>

<script>
import VueECharts from 'vue-echarts';

export default {
  components: {
    VueECharts,
  },
  props: {
    data: {
      type: Array,
    },
  },
  beforeUpdate() {
    this.$refs.chart.clear();
  },
  computed: {
    series() {
      const formatResult = [];
      if (this.data[1].value.length) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.data[0].value.length; i++) {
          const resultByColumn = this.data[1].value[i] - this.data[0].value[i];
          if (resultByColumn > 0) {
            formatResult.push({
              value: resultByColumn,
              itemStyle: {
                color: '#ff5151',
              },
            });
          } else {
            formatResult.push({
              value: resultByColumn,
              itemStyle: {
                color: '#a111ff',
              },
            });
          }
        }
      } else {
        formatResult.push({
          ...this.data[0].value,
          itemStyle: {
            color: '#a111ff',
          },
        });
      }

      return [{
        type: 'bar',
        name: '(this.data[0]).name',
        data: formatResult,
        color: '#a111ff',
        barGap: 0,
        emphasis: {
          itemStyle: {
            color: '#7811c9',
          },
        },
      }];
    },
    getChartData() {
      return {
        toolbox: {
          show: false,
          feature: {
            magicType: {
              type: ['stack', 'tiled'],
            },
            dataView: {},
            saveAsImage: {
              pixelRatio: 2,
            },
          },
        },
        grid: {
          left: '1%',
          right: '1%',
          bottom: '3%',
          top: '35%',
        },
        tooltip: {
          show: false,
        },
        xAxis: {
          data: ['Open', 'Conscientious', 'Extraverted', 'Agreeable', 'Neurotic'],
          position: 'top',
          nameRotate: 20,
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            },
          },
          axisLabel: {
            rotate: 90,
            showMaxLabel: true,
            fontSize: 15,
          },
          axisLine: {
            length: 6,
            lineStyle: {
              width: 2,
            },
          },
          axisTick: {
            lineStyle: {
              width: 2,
            },
          },
        },
        yAxis: {
          maxInterval: 100,
          minInterval: 100,
          min: -4,
          max: 4,
          nameLocation: 'end',
          position: 'right',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: ['#000'],
              width: 2,
            },
          },
          axisLabel: {
            backgroundColor: 'white',
            inside: true,
            showMaxLabel: true,
            fontWeight: 'bold',
            formatter: (value) => {
              let temp;
              if (value === 0) {
                temp = ' ';
              } else if (value === 4) {
                temp = ' ';
              } else if (value === -4) {
                temp = ' ';
              }
              return temp;
            },
          },
          axisLine: {
            show: false,
          },
        },
        series: this.series,
        animationEasing: 'elasticOut',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-chart {
  height: 360px;
  width: 350px;
  background-color: white;
  display: inline-block;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 10px 30px 43px 17px;
  border: 1px solid #ccc;
  padding: 0 7px 0 7px;
}
</style>
