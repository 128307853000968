<template>
  <div class="position-search" id="search">
    <v-text-field
      class="search-panel-static"
      solo
      v-model="searchText"
      type="text"
      label="Search by Text"
    >
      <template v-slot:append>
        <span class="vl"></span>
        <div class="icon-search-position">
          <v-fade-transition leave-absolute>
            <v-btn
              icon
              width="22"
              height="22"
              color="black"
              @click="searchText = ''"
            >
              <v-icon size="18" class="icon-search" color="#212121"
                >mdi-close</v-icon
              >
            </v-btn>
          </v-fade-transition>
        </div>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: '',
    };
  },
  watch: {
    searchText: {
      handler(value) {
        this.$emit('input', value);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.search-panel-static {
  width: 350px;
  display: inline-block;
  position: absolute;
  right: 23px;
  z-index: 10000;
}
.position-search {
  height: 70px;
}
.icon-search {
  font-weight: bold;
}
.vl {
  border-left: 1px solid #bfbfbf;
  height: 32px;
}
.icon-search-position {
  padding-top: 5px;
  padding-left: 10px;
}
</style>
